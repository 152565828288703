import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import urls from '../urls';

import {
  Box,
  Button,
  Icon,
  Link,
  Flex,
  Typography,
  Hamburger,
  MobileSideBar
} from './';
import { useEventListener } from '../hooks/useEventListener';

const StyledHeader = styled(Box)(props =>
  css({
    px: ['5', '5', '5', '165px'],
    py: props.sticky ? ['2'] : ['6', '6', '7', '54px'],
    transition: 'all 250ms linear',
    bg: props.sticky ? 'white' : 'transparent',
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 'appBar',
    borderBottom: props.sticky ? '0.5px solid rgba(0,0,0,0.2)' : 'none'
  })
);

export const Header = () => {
  const [sticky, setSticky] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(false);

  useEventListener('scroll', event => {
    if (window.scrollY > 60) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });

  return (
    <StyledHeader as="header" sticky={sticky}>
      <Flex alignItems="center">
        <Link to="/" disableBorderLine>
          <Flex alignItems="center">
            <Icon name="logo" size={36} color={sticky ? 'accent' : 'white'} />
            <Typography
              variant="title"
              color={sticky ? 'dark' : 'light'}
              fontWeight="normal"
              fontSize="24px"
              marginLeft="2"
            >
              AirPage™
            </Typography>
          </Flex>
        </Link>

        <Box as="nav" ml={['90px']} display={['none', 'none', 'flex', 'flex']}>
          <Link to={ urls.PRODUCT_PAGE }>
            <Typography color={sticky ? 'dark' : 'light'}>
              How it works
            </Typography>
          </Link>
          <Link to={ urls.CONTACT_PAGE } ml="48px">
            <Typography color={sticky ? 'dark' : 'light'}>
              Contact us
            </Typography>
          </Link>
        </Box>
      </Flex>

      <Box display={['flex']}>
        <Button
          as={'a'}
          href={ urls.SIGN_IN }
          color={sticky ? 'secondary' : 'tertiary'}
          size="small"
          mr={['6']}
          display={['none', 'none', 'inline-flex']}
          target="_blank"
        >
          Sign in
        </Button>
        <Button as={Link} to={ urls.SIGN_UP_FORM } variant="contained" size="small">
          Sign up
        </Button>
        <Hamburger
          ml={['3']}
          color={sticky ? 'primary' : 'white'}
          display={['block', 'block', 'none']}
          onClick={() => setShowSidebar(show => !show)}
        />
      </Box>
      <MobileSideBar
        pose={showSidebar ? 'open' : 'closed'}
        onClose={() => setShowSidebar(false)}
      />
    </StyledHeader>
  );
};
