import React from 'react';

const FcTypoSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 90 90" {...props}>
    <title>{'Group 8'}</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__a">
        <stop stopColor="#02CFFF" offset="0%" />
        <stop stopColor="#01A1FF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="url(#prefix__a)" cx={45} cy={45} r={45} />
      <path
        d="M43.608 51.576c.768 2.4 1.824 3.168 4.608 3.408l.096.192-.336 1.968S44.52 57 42.888 57c-3.696 0-7.152.48-7.152.48l-.144-.336.192-1.872c3.312-.24 4.272-.672 4.272-1.824 0-.624-.144-1.392-.432-2.304l-1.584-5.232c-1.152-.048-3.648-.048-4.992-.048-1.872 0-4.128.24-5.232.384L26.04 51.24c-.288.864-.432 1.44-.432 1.872 0 1.392.864 1.968 2.88 1.968h2.736l.096.192-.288 2.064S27.528 57 24.36 57c-2.304 0-5.28.144-5.28.144l.384-2.256c1.776-.192 2.688-1.008 3.744-3.696l9.36-24.528 3.024-.816h.384s4.032 14.928 7.632 25.728zM33.24 31.08l-4.56 12.816h4.224c1.296 0 3.36-.144 4.464-.24L33.576 31.08h-.336zm28.032 14.016l.096-2.112c0-3.216-1.536-5.088-4.224-5.088-3.12 0-5.76 2.4-5.76 2.4l-.24-.144-.576-2.064s5.76-3.36 7.824-3.36c4.176 0 6.672 2.304 6.672 6.192 0 1.728-.864 8.208-.864 11.04 0 1.392.72 2.208 1.968 2.208 1.536 0 2.88-1.2 2.88-1.2l.672.768s-1.776 3.024-4.176 3.84c-2.592 0-4.032-1.296-4.272-3.888l-.192-.048c-2.304 2.304-4.32 3.744-5.28 3.744-3.552 0-6.144-2.496-6.144-5.952 0-1.872 1.008-3.408 2.544-3.84l9.072-2.496zm-.288 6.72l.192-4.896-6.48 1.968c-.912.288-1.392 1.008-1.392 2.016 0 1.824 1.392 3.216 3.312 3.216 1.2 0 3.024-.96 4.368-2.304z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FcTypoSvg;
