import React from 'react';

const FcFlexible = props => (
  <svg width="1em" height="1em" viewBox="0 0 90 90" {...props}>
    <title>{'Group 6'}</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__a">
        <stop stopColor="#02CFFF" offset="0%" />
        <stop stopColor="#01A1FF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="url(#prefix__a)" cx={45} cy={45} r={45} />
      <path
        d="M49.142 59H59V38H35.343l-3-3H27v20-20h-2v30h34v-6h-9.858a9.945 9.945 0 01-.962.86.559.559 0 01-.754-.063c-.595-.653-1.654-.9-2.477-.563a2.223 2.223 0 00-1.368 2.133.557.557 0 01-.495.572 9.922 9.922 0 01-2.25-.006.555.555 0 01-.49-.584 2.218 2.218 0 00-1.354-2.17c-.824-.346-1.895-.101-2.493.56a.556.556 0 01-.761.06 9.931 9.931 0 01-1.582-1.6.556.556 0 01.06-.753c.685-.627.91-1.62.56-2.473-.35-.854-1.187-1.393-2.058-1.37-.286 0-.624-.21-.655-.492a10.017 10.017 0 01.004-2.274.544.544 0 01.578-.491c.977 0 1.83-.53 2.175-1.352a2.216 2.216 0 00-.559-2.495.556.556 0 01-.06-.76 9.933 9.933 0 011.618-1.6.558.558 0 01.755.064c.595.654 1.658.897 2.476.563a2.225 2.225 0 001.369-2.133.556.556 0 01.495-.572 9.984 9.984 0 012.25.006c.293.034.508.29.49.584a2.221 2.221 0 001.351 2.17c.828.346 1.899.1 2.495-.56a.557.557 0 01.76-.06 9.895 9.895 0 011.584 1.599.555.555 0 01-.06.754 2.227 2.227 0 00-.56 2.473 2.221 2.221 0 002.07 1.37c.284 0 .612.211.643.493.085.757.084 1.522-.004 2.274a.557.557 0 01-.585.49l-.124-.003c-.884 0-1.706.544-2.046 1.355a2.216 2.216 0 00.56 2.495c.219.198.245.531.06.76a9.95 9.95 0 01-.656.739zM63 57v-2h2V28H41.737l-3.073-3H31v6h-4 7l3 3h22a4 4 0 014 4v19zM27 31v-6a4 4 0 014-4h9.293l3.073 3H65a4 4 0 014 4v27a4 4 0 01-4 4h-2v6a4 4 0 01-4 4H25a4 4 0 01-4-4V35a4 4 0 014-4h2zm15.016 26.021c2.757 0 5-2.243 5-5s-2.243-5-5-5-5 2.243-5 5 2.243 5 5 5z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FcFlexible;
