import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, compose } from 'styled-system';
import { pxToRem } from '../../utils/style';

import icons from './svgs';

const RawIcon = React.forwardRef(function Icon(props, ref) {
  const { name, ...otherProps } = props;
  const Comp = icons[name];
  return <Comp ref={ref} {...otherProps} />;
});

export const Icon = styled(RawIcon)(
  props =>
    props.size && {
      fontSize: pxToRem(props.size),
    },
  compose(color)
);

Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
