import styled from '@emotion/styled';
import { compose, layout, space } from 'styled-system';
import css from '@styled-system/css';
import { Link as DefaultLink } from 'gatsby';
import shouldForwardProp from '@styled-system/should-forward-prop';

export const Link = styled(DefaultLink, { shouldForwardProp })(
  css({
    display: 'inline-block',
    '&:hover > p': {
      transition: 'all 250ms linear',
      color: 'accent',
    },
  }),
  props =>
    !props.disableBorderLine &&
    css({
      '&[aria-current="page"]': {
        position: 'relative',
        '&:after': {
          position: 'absolute',
          content: '""',
          display: 'block',
          width: '100%',
          height: '2px',
          backgroundColor: 'accent',
        },
      },
    }),
  compose(
    layout,
    space
  )
);
