import { genSpacing, pxToRem } from '../utils/';

export const theme = {
  borders: [
    0,
    '0.5px solid',
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid'
  ],
  radii: [0, 3, 6, 12, 9999, '100%'],
  space: genSpacing(),
  sizes: [18, 20, 36, 40, 48, 100],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em'
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 36, 48].map(pxToRem),
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    heavy: 900
  },
  zIndices: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  breakpoints: ['32em', '48em', '64em', '80em', '106em'], // 512px, 768px, 1024px, 1280px, 1696px
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195
  },
  easing: {
    easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)'
  },
  colors: {
    transparent: 'transparent',
    black: 'rgba(0, 0, 0, 1)',
    white: 'rgba(255, 255, 255, 1)',
    accent: 'rgb(1, 161, 255)', // new color
    accent2: 'rgba(51, 204, 255, 1)', // our old color!
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(40, 35, 35, 1)',
    tertiary: 'rgba(86, 89, 93, 1.00)',
    background: 'rgba(20, 24, 36, 1.00)',
    backgroundAlt: 'rgba(13, 16, 23, 1.00)',
    inputPlaceholder: 'rgba(0, 0, 0, 0.2)',
    error: '#DB3737'
  }
};
