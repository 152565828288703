import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { layout, space, compose } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { darken } from 'polished';
import { pxToRem } from '../utils';

const buildSizeVariant = size => {
  const sizeByVariant = {
    small: css({
      height: [36, 36, 48],
      minWidth: [72, 72, 90],
      paddingX: [3, 5],
      paddingY: [2],
      fontSize: [1, '16px']
    }),
    medium: css({
      paddingX: [5],
      paddingY: [3]
    })
  };
  return sizeByVariant[size];
};

const buildColorVariant = props => {
  const variants = {
    primary: {
      backgroundColor: 'accent',
      color: 'white',
      '&:hover': {
        backgroundColor: darken(0.06, themeGet('colors.accent')(props))
      }
    },
    secondary: {
      backgroundColor: 'white',
      color: 'accent',
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: darken(0.02, themeGet('colors.white')(props)),
      '&:hover': {
        backgroundColor: darken(0.06, themeGet('colors.white')(props)),
        borderColor: darken(0.06, themeGet('colors.white')(props))
      }
    },
    tertiary: {
      backgroundColor: 'transparent',
      color: 'white',
      borderColor: 'white',
      borderWidth: '0.5px',
      borderStyle: 'solid',
      '&:hover': {
        backgroundColor: darken(0.02, themeGet('colors.white')(props)),
        color: 'accent'
      }
    }
  };

  return css({
    ...variants[props.color]
  });
};

export const Button = styled('button', { shouldForwardProp })(
  props =>
    css({
      transition: 'all 250ms ease-in-out',
      textAlign: 'center',
      appearance: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: props.fluid ? 'flex' : 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontFamily: 'inherit',
      fontSize: [1],
      fontWeight: 'semiBold',
      lineHeight: pxToRem(24),
      textDecoration: 'none',
      borderRadius: '1',
      verticalAlign: props.fluid ? undefined : 'middle',
      width: props.fluid ? '100%' : undefined,
      boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0.14), 0px 13px 24px 0px rgba(0, 0, 0, 0.13)',
      '& > *': {
        verticalAlign: 'middle'
      },
      '&:disabled, &[aria-disabled=\'true\']': {
        cursor: 'not-allowed'
      }
    }),
  props =>
    props.variant === 'round' &&
    css({
      display: 'flex',
      borderRadius: '50%',
      width: 60,
      height: 60
    }),
  props => buildSizeVariant(props.size),
  props => buildColorVariant(props),
  props =>
    props.bare &&
    css({
      py: [0, '10px'],
      px: ['3', '10px'],
      boxShadow: 'none',
      border: 'none'
    }),
  compose(
    layout,
    space
  )
);

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['round', 'contained', 'outlined', 'icon'])
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  variant: 'contained'
};
