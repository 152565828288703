import React from 'react';

const FcResponsive = props => (
  <svg width="1em" height="1em" viewBox="0 0 90 90" {...props}>
    <title>{'Group 7'}</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__a">
        <stop stopColor="#02CFFF" offset="0%" />
        <stop stopColor="#01A1FF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="url(#prefix__a)" cx={45} cy={45} r={45} />
      <path
        d="M65 37a4 4 0 014 4v24a4 4 0 01-4 4H53a4 4 0 01-4-4V41a4 4 0 014-4h12zm0 4H53v24h12V41zm-6 18a2 2 0 110 4 2 2 0 010-4zm0-38a4 4 0 014 4v11h-4V25H25v20h23v4h-3v6h3v4H32a2 2 0 110-4h7v-6H25a4 4 0 01-4-4V25a4 4 0 014-4h34z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FcResponsive;
