// Typography
const fontSize = 14; // Default font size in px
const htmlFontSize = 16; // Default font-size used by browsers.

const coef = fontSize / 14;

export const round = value => Math.round(value * 1e5) / 1e5;

export const pxToRem = size => `${(size / htmlFontSize) * coef}rem`;

// Space
const BASELINE = 12;

// Equivalent to [0, 3, 6, 12, 18, 20, 24, 30, 36, 64]
const SCALES = [0, 0.25, 0.5, 1, 1.5, 1.667, 2, 2.5, 3, 5.34];

export const genSpacing = (
  baseline = BASELINE,
  scales = SCALES,
  inPx = false
) => {
  return scales.map(value =>
    inPx ? `${Math.floor(value * baseline)}px` : Math.floor(value * baseline)
  );
};
