import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box } from '../Box';

export const FooterTop = styled(Box)(
  css({
    px: [5, 5, 100, 165, 200, 370],
    paddingBottom: '5',
    paddingTop: '115px',
    display: ['block', 'block', 'flex'],
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  })
);

export const FooterBottom = styled(Box)(
  css({
    bg: 'backgroundAlt',
    py: '6',
    px: [0, 150, 150, 200, 370],
    textAlign: 'center',
    display: ['block', 'block', 'flex'],
    flexDirection: ['column', 'column', 'column', 'row'],
    justifyContent: 'space-between',
    alignItems: 'center'
  })
);
