import styled from '@emotion/styled';
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  compose,
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

export const Box = styled('div', { shouldForwardProp })(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position
  )
);
