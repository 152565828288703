import React from 'react';
import { Box, Flex, Icon, Typography, Link } from '../';
import { FooterTop, FooterBottom } from './styled';
import websiteCopy from '../../copy';
import urls from '../../urls';
export const Footer = () => (
  <Flex flexDirection="column">
    <FooterTop id="footer-top" bg="background">
      {/* Logo and social links */}
      <Box>
        <Link disableBorderLine to="/">
          <Flex>
            <Icon name="logo" size={36} color="accent" />
            <Typography variant="header" color="light" marginLeft="2">
              AirPage™
            </Typography>
          </Flex>
        </Link>
        <Box marginTop="4" maxWidth="200px">
          <Typography variant="caption" color="light">
            {websiteCopy('FOOTER_AIRPAGE_DESC')}
          </Typography>
        </Box>
        <Flex marginTop="90px">
          <a
            href={urls.FACEBOOK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingRight: '6px' }}
          >
            <Icon name="facebook" size={21} color="white" />
          </a>
          &nbsp;
          <a
            href={urls.TWITTER}
            target="_blank"
            style={{ paddingRight: '6px' }}
            rel="noopener noreferrer"
          >
            <Icon name="twitter" size={21} color="white" />
          </a>
          &nbsp;
          <a
            href={urls.INSTAGRAM}
            target="_blank"
            style={{ paddingRight: '6px' }}
            rel="noopener noreferrer"
          >
            <Icon name="instagram" size={21} color="white" />
          </a>
          &nbsp;
          <a
            href={urls.LINKEDIN}
            target="_blank"
            style={{ paddingRight: '6px' }}
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" size={21} color="white" />
          </a>
        </Flex>
      </Box>

      {/* Navigation */}
      <Flex marginTop={['90px', 0]} flexWrap="wrap">
        <Flex.Item flex="1 0 auto" width="50%" flexDirection="column">
          <Typography variant="subhead" color="light">
            PRODUCT
          </Typography>
          <Flex flexDirection="column" marginTop="3">
            <Link disableBorderLine to={urls.PRODUCT_PAGE}>
              <Typography color="light">AirPage™</Typography>
            </Link>
            <Link disableBorderLine to={urls.PRODUCT_PAGE}>
              <Typography color="light">Features</Typography>
            </Link>
          </Flex>
        </Flex.Item>

        <Flex.Item flex="1 0 auto" width="50%" flexDirection="column">
          <Typography variant="subhead" color="light">
            CUSTOMERS
          </Typography>
          <Flex flexDirection="column" marginTop="3">
            <Link disableBorderLine to={urls.PRODUCT_PAGE}>
              <Typography color="light">How it works</Typography>
            </Link>
            {/* <Link disableBorderLine to={urls.PRODUCT_PAGE}>
              <Typography color="light">Entrepreneurs</Typography>
            </Link>
            <Link disableBorderLine to={urls.PRODUCT_PAGE}>
              <Typography color="light">Creatives</Typography>
            </Link>
            <Link disableBorderLine to={urls.PRODUCT_PAGE}>
              <Typography color="light">Freelancers</Typography>
            </Link> */}
          </Flex>
        </Flex.Item>

        <Flex.Item
          flex="1 0 auto"
          width="50%"
          flexDirection="column"
          marginTop="48px"
        >
          <Typography variant="subhead" color="light">
            GET STARTED
          </Typography>
          <Flex flexDirection="column" marginTop="3">
            <Link disableBorderLine to={urls.SIGN_UP_FORM}>
              <Typography color="light">Sign Up</Typography>
            </Link>
          </Flex>
        </Flex.Item>

        <Flex.Item
          flex="1 0 auto"
          width="50%"
          flexDirection="column"
          marginTop="48px"
        >
          <Typography variant="subhead" color="light">
            COMPANY
          </Typography>
          <Flex flexDirection="column" marginTop="3">
            <Link disableBorderLine to={urls.CONTACT_PAGE}>
              <Typography color="light">Contact Us</Typography>
            </Link>
            <Link disableBorderLine to="/brand-guidelines">
              <Typography color="light">Guidelines</Typography>
            </Link>
            <Link disableBorderLine to="/logo-guidelines">
              <Typography color="light">Logo</Typography>
            </Link>
            <Link disableBorderLine to="/credits">
              <Typography color="light">Credits</Typography>
            </Link>
          </Flex>
        </Flex.Item>
      </Flex>
    </FooterTop>

    <FooterBottom id="footer-bottom">
      <Typography variant="caption">
        © AirPage, Inc. All rights reserved
      </Typography>

      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
      >
        <Link
          disableBorderLine
          to="/terms-of-service"
          mt={['3', '3', '3', '0']}
        >
          <Typography color="light">Terms</Typography>
        </Link>
        <Link
          disableBorderLine
          to="/privacy"
          mt={['3', '3', '3', '0']}
          ml={[0, 0, 0, '48px']}
        >
          <Typography color="light">Privacy Policy</Typography>
        </Link>
        <Link
          disableBorderLine
          to="/security-measures"
          mt={['3', '3', '3', '0']}
          ml={[0, 0, 0, '48px']}
        >
          <Typography color="light">Security</Typography>
        </Link>
      </Flex>
    </FooterBottom>
  </Flex>
);
