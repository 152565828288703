import React from 'react';
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import css from '@styled-system/css';
import { Box, Typography } from '../..';
import logo from '../../../assets/Logo-register.png';
import { SiteMetadata } from '../../site-metadata';

export const LegalStyles = styled(Box)(
  css({
    paddingTop: ['146px', '146px', '146px'],
    paddingBottom: ['146px', '146px', '146px'],
    paddingLeft: [5, 5, 200, 165, 370],
    paddingRight: [5, 5, 6, 6],
    background: [
      `no-repeat url(${logo}) bottom right, linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)`,
      `no-repeat url(${logo}) bottom right, linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)`,
      `no-repeat url(${logo}) bottom left, linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)`
    ]
  })
);

export const LegalStylesLight = styled(Box)(
  css({
    paddingTop: ['146px', '146px', '146px'],
    paddingBottom: ['146px', '146px', '146px'],
    paddingLeft: [5, 5, 200, 165, 370],
    paddingRight: [5, 5, 6, 6]
  })
);

export const LegalContainer = ({ children }) => {
  return (
    <LegalStyles className="legal">
      <SiteMetadata robots="NOINDEX, NOFOLLOW" />
      <Global
        styles={css`
          .legal a {
            text-decoration: underline;
          }
        `}
      />
      <Box width={['100%', '100%', '540px', '700px']}>
        <Typography color="light" mt={['6']}>
          {children}
        </Typography>
      </Box>
    </LegalStyles>
  );
};

export const LegalContainerLight = ({ children }) => {
  return (
    <LegalStylesLight className="legal">
      <Global
        styles={css`
          .legal a {
            text-decoration: underline;
          }
        `}
      />
      <Box width={['100%', '100%', '540px', '700px']}>
        <Typography color="dark" mt={['6']}>
          {children}
        </Typography>
      </Box>
    </LegalStylesLight>
  );
};
