import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box, Button } from './';

const StyledHamburger = styled(Button)(
  css({
    px: 0,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent'
    }
  })
);

export const Hamburger = props => (
  <StyledHamburger {...props}>
    <Box
      minWidth="18px"
      height="1px"
      width="100%"
      bg={props.color || 'white'}
    />
    <Box
      minWidth="18px"
      height="1px"
      width="100%"
      bg={props.color || 'white'}
      mt="2"
    />
    <Box
      minWidth="18px"
      height="1px"
      width="100%"
      bg={props.color || 'white'}
      mt="2"
    />
  </StyledHamburger>
);
