import React from 'react';

const FcScheduleSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 90 90" {...props}>
    <title>{'Group'}</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__a">
        <stop stopColor="#02CFFF" offset="0%" />
        <stop stopColor="#01A1FF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="url(#prefix__a)" cx={45} cy={45} r={45} />
      <path
        d="M41 51h8v-6h-8v6zm0 4v4a2 2 0 11-4 0v-4h-6a2 2 0 110-4h6v-6h-6a2 2 0 110-4h6v-4a2 2 0 114 0v4h8v-4a2 2 0 114 0v4h6a2 2 0 110 4h-6v6h6a2 2 0 110 4h-6v4a2 2 0 11-4 0v-4h-8zm13-30v4h-6v-4h6zm6 0h5a4 4 0 014 4v36a4 4 0 01-4 4H25a4 4 0 01-4-4V29a4 4 0 014-4h5v4h-5v36h40V29h-5v-4zm-18 0v4h-6v-4h6zm-9-4a2 2 0 012 2v7a2 2 0 11-4 0v-7a2 2 0 012-2zm12 0a2 2 0 012 2v7a2 2 0 11-4 0v-7a2 2 0 012-2zm12 0a2 2 0 012 2v7a2 2 0 11-4 0v-7a2 2 0 012-2z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FcScheduleSvg;
