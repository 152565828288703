import React from 'react';

const FacebookSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 21" {...props}>
    <title>{'f'}</title>
    <path
      d="M21 1.172v18.656c0 .313-.11.586-.328.82-.219.235-.5.352-.844.352h-5.344v-8.156h2.72l.421-3.14h-3.14V7.686c0-.53.093-.906.28-1.125.25-.28.672-.421 1.266-.421h1.688V3.28a26.408 26.408 0 00-2.438-.094c-1.219 0-2.203.36-2.953 1.079-.75.718-1.125 1.75-1.125 3.093v2.344H8.484v3.14h2.72V21H1.171c-.344 0-.625-.117-.844-.352a1.164 1.164 0 01-.328-.82V1.172C0 .828.11.547.328.328.547.11.828 0 1.172 0h18.656c.313 0 .586.11.82.328.235.219.352.5.352.844z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default FacebookSvg;
