import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { padding, margin } from 'polished';
import { space, layout, typography, compose } from 'styled-system';
import { pxToRem, round } from '../utils';

const buildVariant = (
  fontWeight,
  fontSize,
  lineHeight,
  letterSpacing,
  uppercase
) =>
  css({
    fontWeight,
    fontSize,
    lineHeight: pxToRem(lineHeight),
    letterSpacing: `${round(letterSpacing / fontSize)}em`,
    textTransform: uppercase ? 'uppercase' : null,
  });

const colorVariant = ({ color = 'dark', variant }) => {
  const darkColorByVariant = {
    display: 'primary',
    header: 'secondary',
    body: 'secondary',
    caption: 'tertiary',
  };
  return css({
    color:
      color === 'light' ? 'white' : darkColorByVariant[variant] || 'secondary',
  });
};

const tags = {
  display: 'h1',
  title: 'h2',
  header: 'h3',
  subhead: 'h4',
  body: 'p',
  caption: 'small',
};

const variants = {
  display: buildVariant('bold', [7], 60, 'normal'),
  title: buildVariant('bold', [6], 50, 'normal'),
  header: buildVariant('bold', [5], 38, 'normal'),
  subhead: buildVariant('bold', [2], 26, 'normal', true),
  body: buildVariant('normal', [1, 2], 24, 'normal'),
  caption: buildVariant('normal', [1], 19, 'normal'),
};

const StyledTypography = styled.div(
  {
    ...margin(0),
    ...padding(0),
  },
  props => variants[props.variant],
  props => colorVariant(props),
  compose(
    space,
    layout,
    typography
  )
);

export const Typography = React.forwardRef(function Typography(props, ref) {
  const { variant = 'body', as: asProp, color, className, ...rest } = props;
  const tag = tags[variant] || asProp || tags.body;

  return (
    <StyledTypography
      as={tag}
      variant={variant}
      color={color}
      ref={ref}
      className={className}
      {...rest}
    />
  );
});

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'display',
    'title',
    'header',
    'subhead',
    'body',
    'caption',
  ]),
  color: PropTypes.oneOf(['dark', 'light']),
};
