import React, { useState } from 'react';
import styled from '@emotion/styled';
import posed from 'react-pose';
import css from '@styled-system/css';
import urls from '../../urls';
import { Flex } from '../Flex';
import { Box } from '../Box';
import { Button } from '../Button';
import { Link } from '../Link';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { props } from '@styled-system/should-forward-prop';

const StyledMobileSidebar = styled.div(
  css({
    position: 'fixed',
    backgroundColor: 'backgroundAlt',
    top: 0,
    bottom: 0,
    left: 90,
    boxShadow: '-8px 0px 22px -9px rgba(0,0,0,0.75)',
    px: 7,
    py: 3
  })
);

const PosedMobileSideBar = posed(StyledMobileSidebar)({
  open: {
    right: 0,
    left: 250,
    transition: {
      default: { duration: 300 }
    }
  },
  closed: {
    right: -9999,
    left: 9999,
    transition: {
      default: { duration: 300 }
    }
  }
});

export const MobileSideBar = props => {
  const { pose, onClose } = props;
  const [count, setCount] = useState(0);
  if (pose !== 'closed' && count === 0) {
    setCount(1);
  }
  // hack for flash when site loads...
  const style = !count ? { display: 'none' } : {};
  return (
    <PosedMobileSideBar pose={pose} style={style}>
      <Button variant="contained" color="primary" onClick={onClose}>
        <Icon name="close" />
      </Button>
      <Box>
        <Flex
          flexDirection="column"
          mt="48px"
          style={{ display: 'inline-flex' }}
        >
          <Link to="/">
            <Typography variant="subhead" color={'light'}>
              Home
            </Typography>
          </Link>
          <Link to={urls.PRODUCT_PAGE} mt="32px">
            <Typography variant="subhead" color={'light'}>
              How it works
            </Typography>
          </Link>
          <Link to={urls.CONTACT_PAGE} mt="32px">
            <Typography variant="subhead" color={'light'}>
              Contact us
            </Typography>
          </Link>
        </Flex>
      </Box>
    </PosedMobileSideBar>
  );
};
