import ArrowRight from './ArrowRight';
import Close from './Close';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Linkedin from './Linkedin';
import Logo from './Logo';
import Twitter from './Twitter';
import FcEasiness from './FcEasiness';
import FcFlexible from './FcFlexible';
import FcResponsive from './FcResponsive';
import FcSchedule from './FcSchedule';
import FcTypo from './FcTypo';
import FcSupport from './FcSupport';

export default {
  arrowRight: ArrowRight,
  'arrow-right': ArrowRight,
  close: Close,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  logo: Logo,
  twitter: Twitter,
  'fc-easiness': FcEasiness,
  'fc-flexible': FcFlexible,
  'fc-schedule': FcSchedule,
  'fc-responsive': FcResponsive,
  'fc-typo': FcTypo,
  'fc-support': FcSupport
};
