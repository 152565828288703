import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { space, layout, position, compose } from 'styled-system';

const StyledInput = styled.input(
  props => ({
    display: props.fluid ? 'block' : 'inline-block',
    verticalAlign: props.fluid ? undefined : 'middle',
    width: props.fluid ? '100%' : undefined
  }),
  css({
    px: ['3'],
    py: ['9px', '15px'],
    background: 'white',
    borderRadius: 8,
    border: 'none',
    boxSizing: 'border-box',
    boxShadow:
      'inset 0px 2px 0px 0px rgba(255, 255, 255, 0.14), 0px 31px 24px 0px rgba(0, 0, 0, 0.13);',
    font: 'inherit',
    lineHeight: 24 / 16,
    color: 'primary',

    '&::placeholder': {
      color: 'inputPlaceholder'
    }
  }),
  compose(
    space,
    layout,
    position
  )
);

export const Input = props => <StyledInput as={'input'} {...props} />;

export const Textarea = props => <StyledInput as={'textarea'} {...props} />;
