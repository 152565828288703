import React from 'react';
import styled from '@emotion/styled';

import { GlobalStyles, Theme } from '../theme';
import { Footer, Box, Header } from '../components';

import { ApolloProvider } from '@apollo/react-hooks';
import { client } from '../apollo/client';

export const LayoutContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
});

export const LayoutContentChild = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column'
});

export const MainLayout = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <Theme>
        <GlobalStyles />
        <LayoutContent>
          <Header />
          <LayoutContentChild>{children}</LayoutContentChild>
          <Footer />
        </LayoutContent>
      </Theme>
    </ApolloProvider>
  );
};
