import styled from '@emotion/styled';
import css from '@styled-system/css';
import { space, layout, position, compose } from 'styled-system';
import { Box, Typography, Input } from './';

export const Field = styled(Box)(
  css({
    position: 'relative',
    '& + &': {
      marginTop: '6',
    },
  }),
  compose(
    space,
    layout,
    position
  )
);

Field.Append = styled(Box)(
  {
    position: 'absolute',
    height: 'calc(100% - 4px)',
    right: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  compose(
    space,
    layout,
    position
  )
);

Field.Label = styled(Typography)(
  css({
    color: 'white',
    fontSize: '3',
    fontWeight: 'semiBold',
    [`& + ${Input}`]: {
      marginTop: '3',
    },
  })
);

Field.Error = styled(Typography)(
  css({
    color: 'error',
    marginTop: '2',
  })
);
