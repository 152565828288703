import React from 'react';

const LinkedinSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 21" {...props}>
    <title>{'l'}</title>
    <path
      d="M19.5 0c.406 0 .758.148 1.055.445.297.297.445.649.445 1.055v18c0 .406-.148.758-.445 1.055A1.442 1.442 0 0119.5 21h-18c-.406 0-.758-.148-1.055-.445A1.442 1.442 0 010 19.5v-18C0 1.094.148.742.445.445A1.442 1.442 0 011.5 0h18zM3.234 18h3.141V7.969h-3.14V18zM4.781 6.61c.5 0 .93-.18 1.29-.54.359-.36.538-.78.538-1.265 0-.485-.18-.907-.539-1.266C5.71 3.18 5.281 3 4.781 3c-.5 0-.922.18-1.265.54C3.172 3.897 3 4.32 3 4.804c0 .484.172.906.516 1.265.343.36.765.54 1.265.54zM18 18v-5.484c0-1.563-.219-2.704-.656-3.422-.594-.906-1.61-1.36-3.047-1.36-.719 0-1.36.172-1.922.516-.469.281-.813.64-1.031 1.078h-.047v-1.36h-3V18h3.094v-4.969c0-.781.109-1.375.328-1.781.312-.531.828-.797 1.547-.797.718 0 1.187.297 1.406.89.156.376.234.97.234 1.782V18H18z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default LinkedinSvg;
