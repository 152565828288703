const CODES = {
    FOOTER_AIRPAGE_DESC: {
      en: 'AirPage™ is a website platform that makes it simple to create and deploy custom websites.'
    },
    HOME_PAGE_HEADER: {
      en: 'Launch at the speed of sight'
    },
    HOME_PAGE_DESCRIPTION: {
      en: 'AirPage will empower you to effortlessly craft a website with all the features built to catalyze your writing and creation. Go live on a fast, reliable and hassle-free hosting network that tailors to your needs.'
    },    
    FEATURES_1_HEADER: {
      en: 'Authoring your website like writing on a pad of paper'
    },
    FEATURES_1_DESCRIPTION: {
      en: 'Writing content should flow. We designed AirPage in a simple way to help you focus on your ideas, then making it easy to deploy them beautifully across the multiple screens and devices without worrying about coding and hosting.'
    },
    FEATURES_2_HEADER: {
      en: 'Collaboration built-in for teams'
    },
    FEATURES_2_DESCRIPTION: {
      en: 'AirPage makes it easy to collaborate with teams remotely. Members can stay in sync well and have their own creative process. You and your team can control, update and manage how and when the work is published.'
    },
    FEATURES_3_HEADER: {
      en: 'Database-driven pages and content'
    },
    FEATURES_3_DESCRIPTION: {
      en: 'With AirPage, automatically update all content with simple visual changes. Updating the content database is just a single click away. At the end of the day, all your ideas will be displayed to world at the right time.'
    },
    CONTACT_PAGE_HEADER: {
        en: 'Talk to Us'
    },
    CONTACT_PAGE_DESCRIPTION: {
        en: 'From personal projects to enterprises, we provide services to help make your apps and websites come to life. Tell us about the goals of your projects so we can help you get them off the ground!'
    },
    CONTACT_PAGE_THANKS: {
        en: 'Thanks for the note! We appreciate your interest in AirPage!'
    },
    PRODUCT_PAGE_1_TITLE: {
        en: 'We know the creative process'
    },
    PRODUCT_PAGE_1_DESC: {
        en: 'From ideas to visible projects, there is always a long journey which we creatives call the "design process". The more careful we iterate the raw idea and research, the sharper our vision of future product are. Thus, AirPage is created to seamlessly implement your idea to the world as soon as it forms.'
    },
    PRODUCT_PAGE_2_TITLE: {
        en: 'Deploy websites worry-free'
    },
    PRODUCT_PAGE_2_DESC: {
        en: 'In terms of creating, deploying and maintaining websites, many non-techies find the process seems to be very time-consuming. Don\'t worry! With AirPage, you can deploy your website simply and visually, without needing to deal with infrastructure or code.'
    },
    PRODUCT_PAGE_HEADER_TITLE: {
        en: 'Built for Everyone'
    },
    PRODUCT_PAGE_HEADER_DESC: {
        en: 'From entrepreneurs to creatives, enterprises to personal projects, AirPage is will be your reliable partner when you need a platform for new products, event promotions, daily blog posts, or simply for uploading your artwork to showcase the world. Tell us about your journey, because we would love to listen and support.'
    },
    SIGNUP_BUTTON_TEXT: {
        en: 'Sign up for our beta'
    },
    EARLY_ACCESS_TEXT: {
        en: 'Sign up to access to our beta'
    },
    EARLY_ACCESS_DESC: {
        en: 'Be among the first to gain access to the platform and stay up-to-date with our newsletter'
    },
    EARLY_ACCESS_WAITLIST_TEXT: {
        en: 'Congratulations!'
    },
    EARLY_ACCESS_WAITLIST_DESC: {
        en: 'Stay tuned! You are currently on our waitlist. Be on the lookout for an email with more information coming soon. If you have an invite code, you can skip ahead of the line!'
    },
    CREATIVES_TITLE: {
        en: 'For Creatives'
    },    
    CREATIVES_DESC: {
        en: 'We have created AirPage to help creatives throughout their website creation process. With AirPage you are empowered to effortlessly transform your ideas into beautiful screen designs, and quickly share your message with the world in minutes.'
    },
    FEATURES_EASE_IN_EDITING_TITLE: {
        en: 'Ease in content editing'
    },
    FEATURES_EASE_IN_EDITING_DESC: {
        en: 'Wih Airpage content editing is a superpower. Express your ideas freely. Authoring experience with simple inline edit tools is always at your fingertips.'
    },
    FEATURES_SCHEDULED_CONTENT_TITLE: {
        en: 'Schedule content in advance'
    },
    FEATURES_SCHEDULED_CONTENT_DESC: {
        en: 'AirPage is not only a formating tool but also an editorial calendar. Create content, set time and AirPage will publish your work at the right time while you are having tea.'
    },
    FEATURES_COLLABORATION_TITLE: {
        en: 'Fully-integrated collaboration'
    },
    FEATURES_COLLABORATION_DESC: {
        en: 'We enable better collaboration by providing teams with multiple roles and permissions to create a process that matches the structure of an organization.'
    },
    FEATURES_FLEXIBLE_PROJECT_MANGEMENT_TITLE: {
        en: 'Flexible project management'
    },
    FEATURES_FLEXIBLE_PROJECT_MANGEMENT_DESC: {
        en: 'Multi-site built-in with a variety of roles will surely satisfy any project manager. Easy to start multiple projects and assign them to one more more teams at the same time without interuption.'
    },
    FEATURES_RESPONSIVE_TITLE: {
        en: 'Responsive across multiple devices'
    },
    FEATURES_RESPONSIVE_DESC: {
        en: 'Responsive design is essential part of any interactive platform today. Once you launch your website on AirPage, it will be seen beautifully in all dimensions.'
    },
    FEATURES_TYPOGRAPHY_TITLE: {
        en: 'Fine-tune typography'
    },
    FEATURES_TYPOGRAPHY_DESC: {
        en: 'Along with the writing experience, AirPage will upgrade your journey with a range of variable typefaces. You are free to adjust line-height and tracking to fit your design.'
    },
    INNOVATIVE_TITLE: {
        en: 'Built for Creating and Deploying'
    },
    
    INNOVATIVE_HOSTING_TITLE: {
        en: 'Finest hosting reliability'
    },
    INNOVATIVE_HOSTING_DESC: {
        en: 'Your site will load so quickly for everyone. Design and content updates are just a click away and your visitors will get no more delays in seeing your freshest content.'
    },

    INNOVATIVE_ASSETS_TITLE: {
        en: 'Keep all your assets neat'
    },
    INNOVATIVE_ASSETS_DESC: {
        en: 'Feel free to upload and store all the elements including images, videos, pdf and various file types. Once your assets are ready, you can just drag and drop them onto your website.'
    },

    INNOVATIVE_COLLABORATION_TITLE: {
        en: 'Collaboration-friendly platform'
    },
    INNOVATIVE_COLLABORATION_DESC: {
        en: 'Create content, schedule and approve content to empower your organization. Enable many writers from across of the world working concurrently on your projects.'
    },
    
    NOT_FOUND_TITLE: {
        en: 'Oops, Not Found'
    },
    NOT_FOUND_DESCRIPTION: {
        en: 'Sorry! We cannot find the page you are looking for...'
    },
    

  };
  
  export default (code, lang = 'en') => {
    if (CODES.hasOwnProperty(code)) {
      if (CODES[code].hasOwnProperty(lang)) {
        return CODES[code][lang];
      } else {
        return CODES[code].en;
      }
    } else {
      return code;
    }
  };
  