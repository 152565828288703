import React from 'react';
import { Global } from '@emotion/core';
import { margin, padding } from 'polished';
import 'typeface-hind';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const globalStyles = props => ({
  'html, body, #root': {
    height: '100%',
  },

  body: {
    ...margin(0),
    ...padding(0),
    fontFamily: '\'Hind\', sans-serif',
    hyphens: 'auto',
    maxWidth: '100%',
    orphans: 2,
    overflowWrap: 'break-word',
    overflow: 'auto',
    widows: 2,
    wordWrap: 'break-word',
  },

  '#root': {
    minHeight: '100%',
  },

  html: {
    scrollBehavior: 'smooth',
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
});

export const GlobalStyles = () => <Global styles={globalStyles} />;
