import React from 'react';

const SvgComponent = props => (
  <svg height={90} width={90} {...props}>
    <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
      <stop offset={0} stopColor="#02cfff" />
      <stop offset={1} stopColor="#01a1ff" />
    </linearGradient>
    <g fill="none" fillRule="evenodd">
      <circle cx={45} cy={45} fill="url(#prefix__a)" r={45} />
      <path
        d="M53 24v4H26v36h36V37h4v27a4 4 0 01-4 4H26a4 4 0 01-4-4V28a4 4 0 014-4zM32 56h8a2 2 0 110 4h-8a2 2 0 110-4zm0-8h4a2 2 0 110 4h-4a2 2 0 110-4zm0-8h6a2 2 0 110 4h-6a2 2 0 110-4zm0-8h13a2 2 0 110 4H32a2 2 0 110-4zm32.442-10.706l4.264 4.264a1.005 1.005 0 010 1.42L47.389 48.296l-6.12 1.67a1.005 1.005 0 01-1.234-1.235l1.67-6.12L63.02 21.295a1.005 1.005 0 011.421 0zM47 56h9a2 2 0 110 4h-9a2 2 0 110-4zm10-16a1 1 0 011 1v2a1 1 0 01-2 0v-2a1 1 0 011-1zm-6 8h5a2 2 0 110 4h-5a2 2 0 110-4z"
        fill="#fff"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgComponent;
