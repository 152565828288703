import React from 'react';
import { Box } from './';

export const Image = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="img"
    {...props}
    style={{
      maxWidth: props.maxWidth || '100%',
      width: props.width || 'auto',
      height: props.height || 'auto'
    }}
  />
));
