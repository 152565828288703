import React from 'react';

const LogoSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 36" {...props}>
    <title>{'Logo'}</title>
    <defs>
      <path
        d="M18.082 36l6.886-6.644h8.335L18.082 0 2.8 29.356h8.336L18.082 36zm5.617-9.966l-5.678 5.436-5.617-5.436H8.176L18.02 7.067l9.907 18.967h-4.229z"
        id="prefix__a"
      />
    </defs>
    <use
      fill="currentColor"
      xlinkHref="#prefix__a"
      transform="translate(-2)"
      fillRule="evenodd"
    />
  </svg>
);

export default LogoSvg;
