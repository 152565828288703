import React from 'react';

const TwitterSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 19" {...props}>
    <title>{'t'}</title>
    <path
      d="M21.516 4.75c.03.122.047.32.047.594 0 2.192-.547 4.308-1.641 6.348-1.125 2.162-2.703 3.883-4.735 5.161C12.97 18.284 10.422 19 7.548 19 4.797 19 2.28 18.284 0 16.853c.344.03.734.046 1.172.046 2.281 0 4.328-.685 6.14-2.055-1.093 0-2.054-.312-2.882-.937-.828-.624-1.399-1.408-1.711-2.352.312.03.61.046.89.046.438 0 .875-.046 1.313-.137a4.98 4.98 0 01-2.813-1.69C1.36 8.891.984 7.886.984 6.76v-.092a4.723 4.723 0 002.204.64 5.37 5.37 0 01-1.594-1.736c-.407-.7-.61-1.469-.61-2.306 0-.838.235-1.637.704-2.398A13.506 13.506 0 006.163 4.43a13.906 13.906 0 005.649 1.462 6.49 6.49 0 01-.094-1.096c0-.853.219-1.652.656-2.398A4.755 4.755 0 0114.156.639 4.85 4.85 0 0116.594 0a4.8 4.8 0 011.992.411c.61.274 1.148.64 1.617 1.096a10.18 10.18 0 003.14-1.142c-.374 1.127-1.093 2.01-2.155 2.65.937-.122 1.875-.381 2.812-.777a10.67 10.67 0 01-2.484 2.512z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default TwitterSvg;
