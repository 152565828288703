import React from 'react';

const ArrowRightSvg = props => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
    <title>{'arrow-right'}</title>
    <path
      d="M6.75.125a.457.457 0 01.281-.094c.104 0 .188.032.25.094l6.594 6.625A.297.297 0 0114 7a.297.297 0 01-.125.25l-6.594 6.625a.338.338 0 01-.25.094.457.457 0 01-.281-.094l-.219-.25a.338.338 0 01-.094-.25c0-.104.032-.198.094-.281l5.594-5.563H.375a.362.362 0 01-.266-.11A.362.362 0 010 7.157v-.312c0-.104.036-.193.11-.266a.362.362 0 01.265-.11h11.75L6.531.907a.457.457 0 01-.094-.281c0-.104.032-.187.094-.25l.219-.25z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowRightSvg;
