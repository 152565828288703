import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export const SiteMetadata = ({
  pathname,
  robots = 'INDEX, FOLLOW',
  description,
  title
}) => {
  const {
    site: {
      siteMetadata: {
        siteUrl,
        title: defaultTitle,
        twitter,
        description: defaultDescription,
        facebookAppId
      }
    }
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          twitter
          description
          facebookAppId
        }
      }
    }
  `);

  const useTitle = title || defaultTitle;

  return (
    <Helmet
      defer={false}
      defaultTitle={useTitle}
      titleTemplate={`%s | ${useTitle}`}
    >
      <html lang="en" />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="fb:app_id" content={facebookAppId} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta property="og:title" content={useTitle} />
      <meta property="og:site_name" content={useTitle} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:image" content={`${siteUrl}/airpage-logo-og.png`} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitter} />
      <meta name="ROBOTS" content={robots} />
    </Helmet>
  );
};
