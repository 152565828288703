export default {
  SIGN_IN: `${process.env.GATSBY_APP_URL}/signin`,
  SIGN_UP_FORM: '/#signup-form',
  PRODUCT_PAGE: '/how-it-works',
  CONTACT_PAGE: '/contact',
  FACEBOOK: 'https://www.facebook.com/airpageapp',
  TWITTER: 'https://twitter.com/airpageinc',
  INSTAGRAM: 'https://www.instagram.com/airpageapp',
  LINKEDIN: 'https://www.linkedin.com/company/airpage'
};
